import React, { useContext, useEffect, useState } from 'react';
import * as yup from 'yup';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import { urlQueryToSearchParams } from 'next/dist/next-server/lib/router/utils/querystring';

interface ComponentProps {}

export enum Page {
  DASHBOARD,
  EXPENSE_LIST,
  INVOICE_LIST,
  CREATE_EXPENSE,
  SETTINGS,
  SETTINGS_COMPANY,
  SETTINGS_BANKACCOUNTS,
  BANKITEMS_LIST,
  SETTINGS_VAT,
  SETTINGS_INVOICING,
  SETTINGS_DS,
  EXPENSE_DETAIL,
  EXPENSE_EDIT,
  INVOICE_CREATE,
  INVOICE_DETAIL,
  CAR_LIST,
  CAR_DETAIL,
  CAR_NEW,
  RENTAL_LIST,
  RENTAL_DETAIL,
  ROOMSERVICE_OVERVIEW,
  RECEPTION_OVERVIEW,
  RESERVATION_LIST,
  RESERVATION_CALENDAR,
  RESERVATION_DETAIL,
  REPORT_RESOURCEGROUP,
  RST_DASHBOARD,
  RST_MENUITEMS,
  RST_CALCULATION_DETAIL,
}

interface PageInfo {
  page: Page;
  urlPattern: string;
}

interface Params {
  workspaceUUID?: string;
  applicationParams?: any;
  queryParams?: any;
}

const pages: PageInfo[] = [
  { page: Page.DASHBOARD, urlPattern: '/app/{workspaceUUID}' },
  {
    page: Page.EXPENSE_LIST,
    urlPattern: '/app/{workspaceUUID}/expenses',
  },
  { page: Page.INVOICE_LIST, urlPattern: '/app/{workspaceUUID}/invoices' },
  {
    page: Page.CREATE_EXPENSE,
    urlPattern: '/app/{workspaceUUID}/expense/create',
  },
  {
    page: Page.SETTINGS,
    urlPattern: '/app/{workspaceUUID}/settings',
  },
  {
    page: Page.SETTINGS_COMPANY,
    urlPattern: '/app/{workspaceUUID}/settings/company',
  },
  {
    page: Page.SETTINGS_BANKACCOUNTS,
    urlPattern: '/app/{workspaceUUID}/settings/bank-accounts',
  },
  {
    page: Page.BANKITEMS_LIST,
    urlPattern: '/app/{workspaceUUID}/bank-items',
  },
  {
    page: Page.EXPENSE_DETAIL,
    urlPattern: '/app/{workspaceUUID}/expense/{applicationID}',
  },
  {
    page: Page.EXPENSE_EDIT,
    urlPattern: '/app/{workspaceUUID}/expense/edit/{applicationID}',
  },
  {
    page: Page.INVOICE_CREATE,
    urlPattern: '/app/{workspaceUUID}/invoice/create-invoice',
  },
  {
    page: Page.INVOICE_DETAIL,
    urlPattern: '/app/{workspaceUUID}/invoice/{invoiceId}',
  },
  {
    page: Page.CAR_LIST,
    urlPattern: '/app/{workspaceUUID}/cars',
  },
  {
    page: Page.CAR_DETAIL,
    urlPattern: '/app/{workspaceUUID}/car/{carId}',
  },
  {
    page: Page.CAR_NEW,
    urlPattern: '/app/{workspaceUUID}/car',
  },
  {
    page: Page.RENTAL_LIST,
    urlPattern: '/app/{workspaceUUID}/rentals',
  },
  {
    page: Page.RENTAL_DETAIL,
    urlPattern: '/app/{workspaceUUID}/rental/{carId}',
  },
  {
    page: Page.ROOMSERVICE_OVERVIEW,
    urlPattern: '/app/{workspaceUUID}/roomservice-overview',
  },
  {
    page: Page.RECEPTION_OVERVIEW,
    urlPattern: '/app/{workspaceUUID}/reception-overview',
  },
  {
    page: Page.RESERVATION_LIST,
    urlPattern: '/app/{workspaceUUID}/reservation/list',
  },
  {
    page: Page.RESERVATION_CALENDAR,
    urlPattern: '/app/{workspaceUUID}/reservation/calendar',
  },
  {
    page: Page.RESERVATION_DETAIL,
    urlPattern: '/app/{workspaceUUID}/reservation/{reservationId}',
  },
  {
    page: Page.REPORT_RESOURCEGROUP,
    urlPattern: '/app/{workspaceUUID}/reports/resource-group',
  },

  {
    page: Page.RST_MENUITEMS,
    urlPattern: '/app/{workspaceUUID}/rst/menu-items',
  },
  {
    page: Page.RST_DASHBOARD,
    urlPattern: '/app/{workspaceUUID}/rst',
  },
  {
    page: Page.RST_CALCULATION_DETAIL,
    urlPattern: '/app/{workspaceUUID}/rst/calculation/{menuItemId}',
  },
];

const useNavigation = () => {
  const router = useRouter();
  const [workspaceUUID, setWorkspaceUUID] = useState(null);

  useEffect(() => {
    const w = getWorkspaceUUID();
    setWorkspaceUUID(w);
  }, [router.isReady, router.query['slugs']]);

  const navigate = (page: Page, params: Params = {}) => {
    const url = interpolate(pages.find((t) => t.page == page).urlPattern, params);
    router.push(url);
  };

  const createNavigationLink = (page: Page, params: Params = {}) => {
    const url = interpolate(pages.find((t) => t.page == page).urlPattern, params);
    return url;
  };

  const getWorkspaceUUID = (): string => {
    return router.query['slugs'] as string;
  };

  const hardNavigate = (page: Page, params: Params = {}) => {
    const url = interpolate(pages.find((t) => t.page == page).urlPattern, params);
    window.location.href = url;
  };

  const silentNavigate = (page: Page, params: Params = {}) => {
    const url = interpolate(pages.find((t) => t.page == page).urlPattern, params);
    router.push(url, url, { shallow: true });
  };

  return { navigate, createNavigationLink, getWorkspaceUUID, hardNavigate, silentNavigate, workspaceUUID };
};

const interpolate = (value: string, params: Params) => {
  let val = value.replace(`{workspaceUUID}`, params.workspaceUUID);
  const queryParams = new URLSearchParams(params.queryParams).toString();

  if (Object.keys(params.applicationParams || {}).length == 0) return `${val}?${queryParams}`;
  const interpolatedString = Object.keys(params.applicationParams).reduce((res, item) => {
    return res.replace(`{${item}}`, params.applicationParams[item]);
  }, val);

  return `${interpolatedString}?${queryParams}`;
};

export default useNavigation;
