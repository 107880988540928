import React, { useContext, useEffect, useState } from 'react';
import * as yup from 'yup';
import styled from 'styled-components';
import { ApiContext } from 'api/api';
import { FrontendServiceType, UserInfoOutDTO } from '../../../api/frontend-service.v1';
import { useRouter } from 'next/router';
import { useAuth0 } from '@auth0/auth0-react';
import useNavigation from '../../../hooks/useNavigation';
import { userInfo } from 'os';

interface ComponentProps {}

interface SecurityInfo {
  showExpenseModule: boolean;
  showInvoiceModule: boolean;
  showBankModule: boolean;
  showCars: boolean;
  showRenting: boolean;
  showReception: boolean;
  showRoomservice: boolean;
  showReservation: boolean;
  showReports: boolean;
  showRestaurant: boolean;
}

interface UserInfo {
  userInfo: UserInfoOutDTO;
  security: SecurityInfo;
}

interface UserContextType {
  userInfoWithPermissions: UserInfo;
}

export const UserContext = React.createContext<UserContextType>(undefined);

const UserProvider: React.FC<ComponentProps> = ({ children }) => {
  const { getUserInfo } = useContext(ApiContext);
  const { getWorkspaceUUID, workspaceUUID } = useNavigation();
  const { isAuthenticated } = useAuth0();
  const [userInfoWithPermissions, setUserInfoWithPermissions] = useState<UserInfo>();

  const showExpenseModule = (userInfo: UserInfoOutDTO): boolean => {
    const workspaceUUID = getWorkspaceUUID();
    return userInfo?.companies.find((t) => t.uuid === workspaceUUID)?.roles.some((t) => t === 'ROLE_EXPENSES') || false;
  };
  const showInvoiceModule = (userInfo: UserInfoOutDTO): boolean => {
    const workspaceUUID = getWorkspaceUUID();
    return userInfo?.companies.find((t) => t.uuid === workspaceUUID)?.roles.some((t) => t === 'ROLE_INVOICES') || false;
  };
  const showBankModule = (userInfo: UserInfoOutDTO): boolean => {
    const workspaceUUID = getWorkspaceUUID();
    return (
      userInfo?.companies.find((t) => t.uuid === workspaceUUID)?.roles.some((t) => t === 'ROLE_BANKITEMS') || false
    );
  };
  const showCars = (userInfo: UserInfoOutDTO): boolean => {
    return false;
  };
  const showRenting = (userInfo: UserInfoOutDTO): boolean => {
    return false;
  };
  const showReception = (userInfo: UserInfoOutDTO): boolean => {
    const workspaceUUID = getWorkspaceUUID();
    return (
      userInfo?.companies.find((t) => t.uuid === workspaceUUID)?.roles.some((t) => t === 'ROLE_RECEPTION') || false
    );
  };
  const showRoomservice = (userInfo: UserInfoOutDTO): boolean => {
    const workspaceUUID = getWorkspaceUUID();
    return (
      userInfo?.companies.find((t) => t.uuid === workspaceUUID)?.roles.some((t) => t === 'ROLE_ROOMSERVICE') || false
    );
  };
  const showReservation = (userInfo: UserInfoOutDTO): boolean => {
    const workspaceUUID = getWorkspaceUUID();
    return (
      userInfo?.companies.find((t) => t.uuid === workspaceUUID)?.roles.some((t) => t === 'ROLE_RESERVATION') || false
    );
  };
  const showReports = (userInfo: UserInfoOutDTO): boolean => {
    const workspaceUUID = getWorkspaceUUID();
    return userInfo?.companies.find((t) => t.uuid === workspaceUUID)?.roles.some((t) => t === 'ROLE_REPORT') || false;
  };

  const showRestaurant = (userInfo: UserInfoOutDTO): boolean => {
    const workspaceUUID = getWorkspaceUUID();
    return (
      userInfo?.companies.find((t) => t.uuid === workspaceUUID)?.roles.some((t) => t === 'ROLE_RESTAURANT') || false
    );
  };

  useEffect(() => {
    if (isAuthenticated) {
      getUserInfo((d) => {
        const sec = {
          showExpenseModule: showExpenseModule(d),
          showBankModule: showBankModule(d),
          showInvoiceModule: showInvoiceModule(d),
          showCars: showCars(d),
          showReception: showReception(d),
          showRenting: showRenting(d),
          showRoomservice: showRoomservice(d),
          showReservation: showReservation(d),
          showReports: showReports(d),
          showRestaurant: showRestaurant(d),
        } as SecurityInfo;
        setUserInfoWithPermissions({ userInfo: d, security: sec } as UserInfo);
      });
    } else {
      setUserInfoWithPermissions({
        userInfo: null,
        security: {
          showCars: false,
          showBankModule: false,
          showExpenseModule: false,
          showInvoiceModule: false,
          showReception: false,
          showRenting: false,
          showRoomservice: false,
        } as SecurityInfo,
      } as UserInfo);
    }
  }, [isAuthenticated, workspaceUUID]);

  return (
    <UserContext.Provider
      value={{
        userInfoWithPermissions,
      }}
    >
      {userInfoWithPermissions && <>{children}</>}
    </UserContext.Provider>
  );
};

export default UserProvider;
