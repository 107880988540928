import utc from 'dayjs/plugin/utc';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import timezone from 'dayjs/plugin/timezone';
import weekOfYear from 'dayjs/plugin/weekOfYear';

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import '../styles/styles.scss';

import dayjs from 'dayjs';
import { AppState, Auth0Provider } from '@auth0/auth0-react';
import qs from 'qs';
import axios from 'axios';

dayjs.extend(utc);
dayjs.extend(isSameOrBefore);
dayjs.extend(customParseFormat);
dayjs.extend(timezone);
dayjs.extend(weekOfYear);
dayjs.tz.setDefault('Europe/Prague');

axios.defaults.paramsSerializer = function (params) {
  return qs.stringify(params, { indices: false }); // param=value1&param=value2
};

import { ViewportProvider, useViewport } from 'use-viewport';
import { ApiContext, Provider } from '../api/api';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import getConfig from 'next/config';
import UserProvider from '../components/admin/security/UserProvider';

const MyApp = ({ Component, pageProps }) => {
  const { publicRuntimeConfig } = getConfig();
  const AUTH0_DOMAIN = 'individualstartup.eu.auth0.com';
  const AUTH0_CLIEND_ID = 'sJtzCy1wU5fQHbcXVtH2MitoQQ9Idoob';
  const AUTH0_REDIRECT_URI = publicRuntimeConfig.AUTH0_REDIRECT_URI;
  const router = useRouter();

  console.log(
    `AUTH0_DOMAIN: ${AUTH0_DOMAIN}, AUTH0_CLIEND_ID: ${AUTH0_CLIEND_ID}, AUTH0_REDIRECT_URI: ${AUTH0_REDIRECT_URI}`,
  );

  const onRedirectCallback = (appState: AppState) => {
    if (appState.returnTo) {
      router.push(appState.returnTo);
    }
  };

  return (
    <>
      <ViewportProvider>
        <Auth0Provider
          domain={AUTH0_DOMAIN}
          clientId={AUTH0_CLIEND_ID}
          redirectUri={AUTH0_REDIRECT_URI}
          onRedirectCallback={onRedirectCallback}
          scope={'openid app_metadata user_metadata'}
        >
          <Provider>
            <UserProvider>
              <Component {...pageProps} />
            </UserProvider>
          </Provider>
        </Auth0Provider>
      </ViewportProvider>
    </>
  );
};

export default MyApp;
